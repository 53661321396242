import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Wikistalker`}</p>
    <p>{`Wikistalker, inspired by `}<a parentName="p" {...{
        "href": "http://www.visualcomplexity.com/vc/project_details.cfm?index=7&id=7&domain"
      }}>{`‘Web Stalker‘`}</a>{`, is a way of understanding a concept by only seeing the visualization of the meta-structure of its Wikipedia article. In this visualization, a ‘sun’ is generated for each article. Each ray represents an out-going link. The length of each link shows the semantic relevance between those two linked articles based on ‘Wikipedia Miner‘ measures. Live Demo.`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2012/03/Screen-Shot-2013-11-03-at-6.37.55-PM.png",
        "alt": "Wikistalker"
      }}></img>{` `}</p>
    <p>{`This project is implemented in HTML5 using processingjs and raphaeljs. You can filter links by their relatedness using the sliding bar and sort them alphabetically or by relevance.`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2012/03/Screen-Shot-2013-11-03-at-6.54.58-PM.png",
        "alt": "Wikistalker__Catalouge"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2012/05/w2.jpg",
        "alt": "Wikistalker_kafka"
      }}></img></p>
    <p>{`2020 by Raschin.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      